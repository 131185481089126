<template>
  <div>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Input placeholder="请输入平台名称,用户姓名,手机号" v-model="storePrams.name"/>
        </div>
      </Col>
      <Col span="4">
        <div>
          <DatePicker placeholder="时间查询" v-model="storePrams.create_time" style="width: 100%;"></DatePicker>
        </div>
      </Col>
      <Col span="6">
        <div>
          <Button type="primary" icon="ios-search-outline" @click="searchList">搜索</Button>
        </div>
      </Col>
    </Row>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Button icon="md-add" type="primary" v-if="jurisdictionList.indexOf('admin-store-create') !== -1"
                  @click="addNotice('add')">新增平台
          </Button>
        </div>
      </Col>
    </Row>
    <Table border :columns="platformColumns" :data="storeList">
      <template slot-scope="{ row, index }" slot="operation">
        <!-- 中台创建 is_middleground == 1   saas不允许修改 -->
        <Button type="success" ghost size="small"  :disabled="row.is_middleground == 1 ? true : false" style="margin-right: 5px" @click="same(row, 1)">同步</Button>
        <Button type="info" ghost size="small" :disabled="row.is_middleground == 1 ? true : false" style="margin-right: 5px" @click="changeMsg(row)">次数修改</Button>
        <Button v-if="jurisdictionList.indexOf('admin-store-update') !== -1" type="primary" size="small" :disabled="row.is_middleground == 1 ? true : false"
                style="margin-right: 5px" @click="edit(index,'edit',row)">编辑
        </Button>
        <Button v-if="jurisdictionList.indexOf('platform-delete') !== -1" type="error" size="small"
                :disabled="row.is_middleground == 1 ? true : false"
                style="margin-right: 5px" @click="clearDel(row.id)">删除
        </Button>
        <Button v-if="jurisdictionList.indexOf('platform-configure') !== -1" size="small"
                :disabled="row.is_middleground == 1 ? true : false"
                @click="changeConfigure(index,row)" style="margin-right: 5px">配置权限
        </Button>
        <Button type="info" size="small" @click="getIn(row)">进入平台</Button>
      </template>
      <template slot-scope="{ row }" slot="status">
        <div style="display: flex;align-items: center">
          <div style="margin-right: 5px">当前状态({{ row.it_disabled=== 0 ?'已启用':'已禁用' }})</div>
          <Button type="warning" size="small" v-if="row.it_disabled === 0" style="margin-right: 5px" @click="changeOff(row)">禁用</Button>
          <Button type="success" size="small" v-else style="margin-right: 5px" @click="changeOn(row)">启用</Button>
        </div>
      </template>
      <template slot-scope="{ row }" slot="identity">
        <div  v-for="(item,  index) in identity_list"  :key="index" >
          <div  v-if="row.identity == item.id" >{{item.name}}</div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="contract">
        <div>{{ row.contracts_num }} <span style="color: #00a6ff">({{ row.contracts_usenum || 0 }})</span></div>
      </template>
      <template slot-scope="{ row }" slot="notContract">
        <div>{{ row.not_contracts_num }} <span style="color: #00a6ff">({{ row.not_contracts_usenum || 0 }})</span></div>
      </template>
      <template slot-scope="{ row }" slot="message">
        <div>{{ row.message_num }} <span style="color: #00a6ff">({{ row.message_usenum || 0 }})</span></div>
      </template>
      <template slot-scope="{ row,index }" slot="href">
        <Button type="success" size="small"  @click="handleCopy(index,row.link_url)" >{{ row.un_name }}</Button>
      </template>
      <template slot-scope="{ row }" slot="warrant">
        <div v-if="row.is_warrant === 0">未认证</div>
        <div v-else>已认证</div>
      </template>
    </Table>
    <br/>
    <Page :total="total"
          :current="storePrams.page"
          style="text-align:right" show-elevator show-total
          @on-change="pageChange"
          :page-size="storePrams.limit"/>
    <Modal
        title="配置权限"
        v-model="configure"
        :mask-closable="false"
        width="500"
        footer-hide
        @on-ok="submitConfigure"
        @on-cancel="cancelConfigure"
        class-name="vertical-center-modal">
      <Form :model="formConfigure" :label-width="120" style="width: 450px;">
        <div class="new_clacont">
          <FormItem label="云储配置" v-if="jurisdictionList.indexOf('admin-platform-configure-cloud') !== -1">
            <i-switch v-model="formConfigure.cloud_status" size="large" @on-change="changeFirst">
              <span slot="open">开启</span>
              <span slot="close">关闭</span>
            </i-switch>
          </FormItem>
          <FormItem label="OEM配置" v-if="jurisdictionList.indexOf('admin-platform-configure-oem') !== -1">
            <i-switch v-model="formConfigure.oem_status" size="large" @on-change="changeThird">
              <span slot="open">开启</span>
              <span slot="close">关闭</span>
            </i-switch>
          </FormItem>
          <FormItem label="公章审核">
            <i-switch v-model="formConfigure.seal_status" :true-value="1" :false-value="0" size="large" @on-change="changeSeal">
              <span slot="open">开启</span>
              <span slot="close">关闭</span>
            </i-switch>
          </FormItem>
          <FormItem label="多签权限">
            <i-switch v-model="formConfigure.sign_status" :true-value="1" :false-value="0" size="large" @on-change="changeSign">
              <span slot="open">开启</span>
              <span slot="close">关闭</span>
            </i-switch>
          </FormItem>
        <FormItem label="模板组件" >
            <i-switch v-model="formConfigure.template_status" :true-value="1" :false-value="0" size="large" @on-change="changeZujian">
              <span slot="open">开启</span>
              <span slot="close">关闭</span>
            </i-switch>            
        </FormItem>         
        <FormItem label="实名认证">
          <i-switch v-model="formConfigure.need_status" :true-value="1" :false-value="0" size="large" @on-change="changeNeed">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
          </FormItem>
        <FormItem label="实名认证次数" v-if="formConfigure.need_status === 1">
          <div style="display: flex;align-items: center">
            <Input v-model="formConfigure.real_num" type="number" placeholder="请输入实名认证次数" style="width: 240px"></Input>
            <div style="margin-left: 10px" @click="userTrue"><Button type="primary" ghost>确认</Button></div>
          </div>
        </FormItem>

        <FormItem label="小程序发布开关">
          <i-switch v-model="formConfigure.applet_status" :true-value="1" :false-value="0" size="large" @on-change="changeApplet">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>

        <FormItem label="版本切换开关">
          <i-switch v-model="formConfigure.edition_status" :true-value="1" :false-value="0" size="large" @on-change="changeEdition">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>

        <FormItem label="批签开关">
          <i-switch v-model="formConfigure.is_batch_signing" :true-value="1" :false-value="0" size="large" @on-change="changeMoreSign">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <!-- v-show="showTemplateSharing" -->
        <FormItem label="模板共享" >
            <i-switch v-model="formConfigure.is_template_sharing" :true-value="1" :false-value="0" size="large" @on-change="changeTemplateSharing">
              <span slot="open">开启</span>
              <span slot="close">关闭</span>
            </i-switch>            
        </FormItem> 
        <FormItem label="默认签署者共享" >
            <i-switch v-model="formConfigure.is_signer_sharing" :true-value="1" :false-value="0" size="large" @on-change="changeisSignerSharing">
              <span slot="open">开启</span>
              <span slot="close">关闭</span>
            </i-switch>            
        </FormItem> 
       </div>
      <!--  :autosize="{minRows: 2,maxRows: 5}" -->
      <FormItem label="独立域名">
          <Input v-model="formConfigure.single_url" 
                 placeholder="请输入独立域名"></Input>
                 <Button @click="submitDly" style="margin-top:8px" type="primary">确定</Button>
        </FormItem>

        <FormItem label="短信签名">
          <Input v-model="formConfigure.synopsis" type="textarea" :autosize="{minRows: 2,maxRows: 5}"
                 placeholder="请输入短信签名"></Input>
                 <Button @click="submitConfigure" style="margin-top:8px" type="primary">确定</Button>
        </FormItem>
        <!-- <FormItem label="短信配置" v-if="jurisdictionList.indexOf('admin-platform-configure-sms') !== -1">
          <i-switch :disabled="formConfigure.synopsis.length<=0" v-model="formConfigure.sms_status" size="large"
                    @on-change="changeSecond">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
          <span>(请输入短信签名后,方可开启。)</span>
        </FormItem> -->
      </Form>
    </Modal>
    <Modal
        :title="formTitle"
        v-model="addModel"
        :mask-closable="false"
        width="600"
        :footer-hide="true"
        class-name="vertical-center-modal"

        @on-cancel="cancel">
      <Form ref="formItem" :rules="ruleValidate" :model="platform" :label-width="120" style="width: 500px">
        <FormItem label="账户" prop="account">
          <Input v-model="platform.account" placeholder="请输入账户"></Input>
        </FormItem>
        <FormItem label="密码" prop="password">
          <Input v-model="platform.password" type="password" placeholder="请输入密码"></Input>
        </FormItem>
        <FormItem label="用户姓名" prop="contacts_name">
          <Input v-model="platform.contacts_name" placeholder="请输入用户姓名"></Input>
        </FormItem>
        <FormItem label="用户手机号" prop="phone">
          <Input v-model="platform.phone" placeholder="请输入用户手机号"></Input>
        </FormItem>
        <FormItem label="平台名称" prop="un_name">
          <Input v-model="platform.un_name" placeholder="请输入平台名称"></Input>
        </FormItem>
        <FormItem label="用户身份">
          <Select v-model="platform.identity" placeholder="请选择平台身份">
            <Option v-for="(item,  index) in identity_list" :value='item.id'  :key="index">{{item.name}}</Option>
<!--            <Option value='2'>企业</Option>-->
          </Select>
        </FormItem>
        <FormItem label="平台有效期">
          <Select v-model="platform.change" placeholder="请选择有效期类型">
            <Option value='0'>永久</Option>
            <Option value="TIME">选择时间</Option>
          </Select>
        </FormItem>
        <FormItem v-if="platform.change === 'TIME'">
          <DatePicker type="date" v-model="platform.date" placeholder="请选择平台有效期" style="width: 380px"></DatePicker>
        </FormItem>
        <!--        <FormItem label="账户版本">-->
        <!--          <RadioGroup v-model="platform.is_warrant">-->
        <!--            <Radio label="0">未认证</Radio>-->
        <!--            <Radio label="1">已认证</Radio>-->
        <!--          </RadioGroup>-->
        <!--        </FormItem>-->
        <FormItem>
          <Button type="primary" @click="handleSubmit('formItem')">确认</Button>
          <Button @click="handleReset('formItem')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
    <Modal
        title="编辑次数"
        v-model="editMessage"
        :mask-closable="false"
        width="400"
        :footer-hide="true"
        class-name="vertical-center-modal">
      <Form ref="messageForm" :rules="ruleMessageForm" :model="messageForm" :label-width="140" style="width: 500px">
        <FormItem label="CA合同次数" prop="contract_number" v-if=" platform_identity == 2">
          <Input v-model="messageForm.contract_number" type="number" placeholder="请输入CA合同次数" style="width:150px" ></Input>
          <i v-if="!is_loading"  class="ivu-icon ivu-icon-ios-loading  ivu-load-loop"></i>
        </FormItem>
<!--        <FormItem label="未认证合同次数" prop="not_contract_number">-->
<!--          <Input v-model="messageForm.not_contract_number" type="number" placeholder="请输入合同次数"></Input>-->
<!--        </FormItem>-->
        <FormItem label="短信次数" prop="sms_number" v-if="userInfo.level == 0 ">
          <Input v-model="messageForm.sms_number" type="number" placeholder="请输入短信次数"  style="width:150px"></Input>
          <i v-if="!is_loading"  class="ivu-icon ivu-icon-ios-loading  ivu-load-loop"></i>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleMessage('messageForm')">确认</Button>
          <Button @click="resetMessage('messageForm')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
import {
  storeList,
  storeBan,
  storeAdd,
  storeDel,
  storeSynchro,
  storeInfo,
  storeUpdate,
  storeSetup,
  set_singleurl,
  storeGetin,
  storeUpdateScnum,
  sealSwitch, manysiginWitch, authenticationWitch, appletSwitch, editionSwitch,template_witch,batchsigning_witch,template_sharing_witch
} from '../../api/platform'
import moment from "moment";

export default {
  data() {
    return {
      identity_list:[
        {id:'1', name:'个人(非CA)'},
        {id:'2', name:'企业(CA)'},
        {id:'3', name:'无小程序'}
      ],
      platformColumns: [
        {
          title: '#',
          key: 'id',
          width: 80,
          align:'center'
        },
        {
          title: '账户',
          key: 'account',
          width: 140,
          align:'center'
        },
        {
          title: '用户姓名',
          key: 'contacts_name',
          width: 250,
          align:'center'
        },
        {
          title: '手机号',
          key: 'phone',
          width: 140,
          align:'center'
        },
        {
          title: '平台身份',
          slot: 'identity',
          width: 120,
          align:'center'
        },
        {
          title: '平台名称',
          key: 'un_name',
          width: 180,
          slot: 'href',
          align:'center'
        },
        {
          title: '平台创建时间',
          key: 'created_at',
          width: 180,
          align:'center'
        },
        {
          title: '有效期',
          key: 'effective_time',
          width: 180,
          align:'center'
        },
        {
          title: '平台状态',
          key: 'it_disabled',
          slot: 'status',
          width: 200,
          align:'center'
        },
        {
          title: 'CA合同',
          key: 'contracts_num',
          width: 150,
          slot: 'contract',
          align:'center'
        },
        {
          title: '普通合同',
          key: 'not_contracts_num',
          width: 150,
          slot: 'notContract',
          align:'center'
        },
        {
          title: '短信',
          key: 'message_num',
          width: 100,
          slot: 'message',
          align:'center'
        },
        {
          title: '操作',
          key: 'action',
          width: 420,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      total: 0,
      editMessage:false,
      storeList: [],
      storeId: '',
      configure: false,
      showModel: false,
      addModel: false,
      platform: {
        date: '',
        identity: '1',
        is_warrant: '0',
        change: '0',
        un_name: '',
        phone: '',
        contacts_name: '',
        password: '',
        account: '',
        effective_time: '',
        store_id: ''
      },
      messageForm: {
        sms_number: '',
        contract_number: 0,
        not_contract_number: '99999999',
        store_id:''
      },
      ruleValidate: {
        account: [
          {required: true, message: '请输入账户', trigger: 'blur'},
          {type: 'string', min: 3, max: 12, message: '账户不得少于三个字符，且不超过十二个字符', trigger: 'blur'}
        ],
        // password: [
        //   {required: true, message: '请输入密码', trigger: 'blur'},
        //   {type: 'string', min: 6, message: '密码不得少于六个字符', trigger: 'blur'}
        // ],
        contacts_name: [
          {required: true, message: '请输入用户姓名', trigger: 'blur'}
        ],
        un_name: [
          {required: true, message: '请输入平台名称', trigger: 'blur'}
        ],

        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {type: 'string', min: 11, message: '手机号不得少于十一个字符', trigger: 'blur'}
        ],
        store_num: [
          {required: true, message: '请输入平台数量', trigger: 'blur'}
        ]
      },
      ruleMessageForm:{
        contract_number: [
          {required: true, message: '请输入CA合同次数', trigger: 'blur'}
        ],
        not_contract_number: [
          {required: true, message: '请输入普通合同次数', trigger: 'blur'}
        ],
        sms_number: [
          {required: true, message: '请输入短信次数', trigger: 'blur'}
        ],
      },
      unList: [],
      storePrams: {
        limit: 20,
        page: 1,
        name: '',
        create_time: ''
      },
      formTitle: '新增平台',
      headers: {},
      editId: '',
      showTemplateSharing:true,
      formConfigure: {
        cloud_status: false,
        sms_status: false,
        oem_status: false,
        sign_status:0,
        synopsis: '速速云签',
        seal_status:0,
        applet_status:0,        
        template_status:0,
        edition_status:0,
        need_status:0,
        is_batch_signing:0,
        is_template_sharing:0,
        is_signer_sharing:0,
        single_url:'',
      },
      jurisdictionList: [],
      userInfo:{},
      is_loading:false,
        platform_identity:1
    }
  },
  created() {
    this.getList()
    this.jurisdictionList = this.$store.state.auth
    this.userInfo = this.$store.state.userInfo
  },
  mounted() {
  },
  computed: {},
  methods: {
    userTrue(){
      let val = {
        real_num:this.formConfigure.real_num,
        status:this.formConfigure.need_status,
        store_id:this.storeId
      }
      authenticationWitch(val).then(res=>{
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    changeApplet(){
      appletSwitch({store_id: this.storeId,status:this.formConfigure.applet_status}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeEdition(){
      editionSwitch({store_id: this.storeId,status:this.formConfigure.edition_status}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    // http://192.168.0.201/adminapi/store/batchsigning_witch 
    changeMoreSign(){
      batchsigning_witch({store_id: this.storeId,status:this.formConfigure.is_batch_signing}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    //模板共享开关
    changeTemplateSharing(){
      template_sharing_witch({store_id: this.storeId,status:this.formConfigure.is_template_sharing,name:'is_template_sharing'}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    //默认签署者共享开关
    changeisSignerSharing(){
      template_sharing_witch({store_id: this.storeId,status:this.formConfigure.is_signer_sharing,name:'is_signer_sharing'}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
      
    //默认签署者共享开关
    // changeisSignerSharing(){
    //   template_sharing_witch({store_id: this.storeId,status:this.formConfigure.is_signer_sharing,name:'is_signer_sharing'}).then(res => {
    //     this.$Message.success(res.msg)
    //     this.getList()
    //   }).catch(res => {
    //     this.$Message.error(res.msg)
    //   })
    // },
    changeZujian(e){
      console.log("e",e)
      template_witch({store_id: this.storeId,status:this.formConfigure.template_status}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    handleMessage(name){
      this.$refs[name].validate((valid) => {
        if (valid) {
          storeUpdateScnum(this.messageForm).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
            this.editMessage = false
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        } else {
          return
        }
      })
    },
    changeSeal(){
      sealSwitch({store_id: this.storeId,status:this.formConfigure.seal_status}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeSign(){
      manysiginWitch({store_id: this.storeId,status:this.formConfigure.sign_status}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeNeed(e){
      if(e === 0){
        let val = {
          real_num:0,
          status:e,
          store_id:this.storeId
        }
        authenticationWitch(val).then(res=>{
          this.$Message.success(res.msg)
          this.getList()
        }).catch(res=>{
          this.$Message.error(res.msg)
        })
      }
    },
    resetMessage(name){
      this.$refs[name].resetFields();
    },
    same(row, t) {
      if (t == 2) {
        this.is_loading = false
      }
      storeSynchro({store_id: row.id}).then(res => {
        if (t == 2) {
          this.is_loading = true
        } else {
          this.$Message.success(res.msg)
        }
        this.getList()
        this.messageForm.sms_number=String(res.data.message_num)
        this.messageForm.contract_number=String(res.data.contracts_num)
       this.platform.contracts_num = res.data.contracts_num
        // this.messageForm.not_contract_number=String(res.data.not_contracts_num)
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeMsg(row){
      this.messageForm.sms_number=String(row.message_num)
      this.messageForm.contract_number=String(row.contracts_num)
      this.messageForm.not_contract_number=String(row.not_contracts_num)
      this.same(row, 2)
      this.messageForm.store_id=row.id
        this.platform_identity = row.identity
      this.editMessage = true
    },
    getIn(row) {
      storeGetin({store_id: row.id}).then(res => {
        window.open(res.data.url)
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    getList() {
      storeList(this.storePrams).then(res => {
        this.storeList = res.data.data
        this.total = res.data.total
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    clearDel(id) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此用户，删除后无法恢复！！！',
        onOk: () => {
          storeDel({store_id: id}).then(res => {
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    searchList() {
      this.storePrams.create_time = moment(this.storePrams.create_time).format('YYYY-MM-DD')
      if (this.storePrams.create_time == 'Invalid date') {
        this.storePrams.create_time = ''
      }
      this.storePrams.page = 1
      this.getList()
    },
    edit(index, type, row) {
      this.reset()
      if (type === 'edit') {
        this.formTitle = '编辑平台信息'
      }
      console.log('row',row)
      storeInfo({store_id: row.id}).then(res => {
        if (res.data.effective_time === '永久') {
          this.platform.change = '0'
        } else {
          this.platform.change = 'TIME'
          this.platform.date = res.data.effective_time
        }
        if (res.data.is_warrant === 0) {
          this.platform.is_warrant = '0'
        } else {
          this.platform.is_warrant = '1'
        }        
        this.platform.identity = String(res.data.identity)
        this.platform.store_id = res.data.id
        this.platform.message_num = String(res.data.message_num)
        this.platform.contracts_num = String(res.data.contracts_num)
        this.platform.un_name = res.data.un_name
        this.platform.phone = res.data.phone
        this.platform.contacts_name = res.data.contacts_name
        this.platform.account = res.data.account
        if( this.platform.contracts_num == 'null' ){
          this.same(row, 2)
        }
      }).catch(res => {
        this.$Message.error(res.msg)
      })
      this.addModel = true
    },
    reset() {
      this.platform.store_id = ''
      this.platform.message_num = ''
      this.platform.contracts_num = ''
      this.platform.un_name = ''
      this.platform.phone = ''
      this.platform.contacts_name = ''
      this.platform.is_warrant = '0'
      this.platform.change = '0'
      this.platform.password = ''
      this.platform.account = ''
      this.platform.effective_time = ''
      this.platform.date = ''
      this.platform.identity = '1'
    },
    handleCopy(index, row) {
      this.copyData = row
      this.copy(this.copyData)
    },
    copy(data) {
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$Message.success('复制成功');
      oInput.remove()
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.platform.change === '0') {
            this.platform.effective_time = 0
          } else {
            this.platform.effective_time = moment(this.platform.date).format('YYYY-MM-DD')
          }
          if (this.platform.store_id) {
            storeUpdate(this.platform).then(res => {
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.addModel = false
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          } else {
              this.platform.not_contracts_num = '999999'
              this.platform.message_num = '100'
            if(this.platform.password == "" || this.platform.password.length<6){
              return  this.$Message.error('请输入密码且长度不能少于6');
            }
            storeAdd(this.platform).then(res => {
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.addModel = false
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          }
        } else {
          this.$Message.error('请输入平台信息');
        }
      })
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    submit() {

    },
    cancel() {
      this.reset()
    },
    handleSuccess() {

    },
    handleFormatError() {

    },
    show(index) {
      console.log(index)
      this.showModel = true
    },
    addNotice(type) {
      if (type === 'add') {
        this.formTitle = '新增平台'
      }
      this.addModel = true
    },
    changeOff(row) {
      if (this.jurisdictionList.indexOf('admin-store-disable') !== -1) {
        this.$Modal.confirm({
          title: '禁用提示',
          content: '请问您是否确认禁用此条员工账户',
          onOk: () => {
            if (row.it_disabled === 0) {
              let changeType = 1
              storeBan({store_id: row.id, type: changeType}).then(res => {
                this.getList()
                this.$Message.success(res.msg)
              }).catch(res => {
                this.$Message.error(res.msg)
              })
            }
          },
          onCancel: () => {
          }
        });
      }

    },
    changeOn(row) {
      if (this.jurisdictionList.indexOf('admin-store-disable') !== -1) {
        this.$Modal.confirm({
          title: '启用提示',
          content: '请问您是否确认启用此条员工账户',
          onOk: () => {
            if (row.it_disabled === 1) {
              let changeType = 0
              storeBan({store_id: row.id, type: changeType}).then(res => {
                this.getList()
                this.$Message.success(res.msg)
              }).catch(res => {
                this.$Message.error(res.msg)
              })
            }
          },
          onCancel: () => {
          }
        });
      }
    },
    submitConfigure() {
      this.changeSecond()
    },
    cancelConfigure() {

    },
    changeConfigure(index, row) {      
      this.showTemplateSharing = (row.id == 6) ? false : true
      if (row.cloud_status === 1) {
        this.formConfigure.cloud_status = true
      } else {
        this.formConfigure.cloud_status = false
      }
      if (row.sms_status === 1) {
        this.formConfigure.sms_status = true
      } else {
        this.formConfigure.sms_status = false
      }
      if (row.oem_status === 1) {
        this.formConfigure.oem_status = true
      } else {
        this.formConfigure.oem_status = false
      }
      this.storeId = row.id
      this.formConfigure.synopsis = row.sign
      this.configure = true
      console.log(row)
      this.formConfigure.template_status = row.is_template ? Number(row.is_template) : 0
      this.formConfigure.seal_status = row.is_borrow_seal ? Number(row.is_borrow_seal) : 0
      this.formConfigure.sign_status = row.is_many_sign ? Number(row.is_many_sign) : 0
      this.formConfigure.need_status = row.is_face_nucleus ? Number(row.is_face_nucleus) : 0
      this.formConfigure.edition_status = row.is_version ? Number(row.is_version) : 0
      this.formConfigure.applet_status = row.is_release_applet ? Number(row.is_release_applet) : 0
      this.formConfigure.is_template_sharing = row.is_template_sharing ? Number(row.is_template_sharing) : 0
      this.formConfigure.is_signer_sharing = row.is_signer_sharing ? Number(row.is_signer_sharing) : 0      
      this.formConfigure.real_num = row.nucleus_num ? row.nucleus_num : '0'
      this.formConfigure.is_batch_signing = row.is_batch_signing ? row.is_batch_signing : '0'
      this.formConfigure.single_url = row.single_url
    },
    changeFirst(e) {
      let status
      if (e === true) {
        status = 1
      } else {
        status = 0
      }
      storeSetup({type: 2, status: status, store_id: this.storeId}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeSecond() {
      // let status
      // if (e === true) {
      //   status = 1
      // } else {
      //   status = 0
      //   this.formConfigure.synopsis = ''
      // }
      storeSetup({type: 3, store_id: this.storeId, sms_sign: this.formConfigure.synopsis}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    // http://192.168.0.201/adminapi/store/set_singleurl
    submitDly(){
      set_singleurl({ store_id: this.storeId, url: this.formConfigure.single_url}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeThird(e) {
      let status
      if (e === true) {
        status = 1
      } else {
        status = 0
      }
      storeSetup({type: 1, status: status, store_id: this.storeId}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
  }
}
</script>
<style>
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ivu-modal {
  top: 0;
}

.flex-style {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.modal-form {

}

.form-label {
  padding-right: 12px;
  width: 120px;
  text-align: right;
  font-size: 14px;
  color: #515a6e;
}
.new_clacont {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 450px;
  flex-wrap:wrap;
  /* gap: 8px; */

}

.link-href {
  color: #00a6ff;
  cursor: pointer
}

.link-href:hover {
  text-decoration: underline;
}
  .vertical-center-modal  .ivu-icon-ios-loading {
      margin-left: 10px;
  }
</style>
<style scoped >
::v-deep .new_clacont .ivu-form-item-content{   
      margin-right: 48px;  
}

</style>
